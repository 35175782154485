:root {
  --color-primary: #29abe2;
  --color-primary-darker: #2390be;
  --color-button-disabled: #b3b3b3;
  --color-bg: #f2f2f2;
  --color-bg-dark: #333;
  --color-bg-darker: #222;
  --color-player-background: #111;
  --color-connected: #9bc53d;
  --color-disconnected: #c3423f;
  --color-pending: #fde74c;

  --text-bright: #fff;
  --text-light: #f2f2f2;
  --text-light-75: #f2f2f299;
  --text-dark: #666;
  --text-dark-hover: #000;
  --text-primary: var(--color-primary);
  --text-primary-darker: var(--color-primary-darker);
  --text-success: #8cc63f;

  --device-panel-width: 700px;
  --device-panel-width-mobile: 100%;
  --login-width: 500px;
}

@font-face {
  font-family: 'robotomedium';
  src: url('assets/fonts/Roboto-Medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotolight';
  src: url('assets/fonts/Roboto-Light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Linotype Regular';
  font-style: normal;
  font-weight: normal;
  src: url('assets/Linotype - UniversLTStd-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Linotype Bold';
  font-style: bold;
  font-weight: bold;
  src: url('assets/Linotype_UniversLTStd.otf') format('opentype');
}

body,
html {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'robotomedium';
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

a:active {
  color: var(--color-primary);
  text-decoration: none;
}

.button-primary {
  width: fit-content;
  display: inline-block;
  font-size: medium;
  line-height: 25px;
  background-color: var(--color-primary);
  border-radius: 10px;
  border: none;
  color: var(--text-bright);
  padding: 8px 32px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

.button-primary:hover {
  background-color: var(--color-primary-darker);
}

// Custom Checkbox

/* Customize the label (the container) */
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: solid 2px var(--color-primary);
  margin-left: 10px;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: #ddd;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: var(--color-primary);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 7px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// PLAYER

#player {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #323232;
  z-index: 1000;

  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#player:before {
  content: '';
  display: block;
  padding-top: 66%;
}

#playButton {
  width: 15%;
  max-width: 200px;
  cursor: pointer;
}

#Video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;

  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#fullscreenButton {
  display: block;
  background-color: rgb(255, 255, 255, 0.5);
  padding: 8px;
  border-radius: 5px;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 32px;
  height: 32px;
  z-index: 1002;
  transition: all 0.1s ease-in-out;
}

#fullscreenButton:hover {
  top: 28px;
  right: 28px;
  width: 36px;
  height: 36px;
}

// END

// Toast Styles

// /* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */

.toastify {
  padding: 12px 20px;
  color: #fff;
  display: inline-block;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.12), 0 10px 36px -4px rgba(77, 96, 232, 0.3);
  background: -webkit-linear-gradient(315deg, var(--color-primary), var(--color-primary-darker));
  background: linear-gradient(135deg, var(--color-primary), var(--color-primary-darker));
  position: fixed;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
  max-width: calc(50% - 20px);
  z-index: 2147483647;
}
.toastify.on {
  opacity: 1;
}
.toast-close {
  background: 0 0;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  opacity: 0.4;
  padding: 0 5px;
}
.toastify-right {
  right: 15px;
}
.toastify-left {
  left: 15px;
}
.toastify-top {
  top: -150px;
}
.toastify-bottom {
  bottom: -150px;
}
.toastify-rounded {
  border-radius: 25px;
}
.toastify-avatar {
  width: 1.5em;
  height: 1.5em;
  margin: -7px 5px;
  border-radius: 2px;
}
.toastify-center {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  max-width: fit-content;
  max-width: -moz-fit-content;
}
@media only screen and (max-width: 360px) {
  .toastify-left,
  .toastify-right {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: fit-content;
  }
}

// // Toast Styles END

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
